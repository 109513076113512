import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Me`}</h1>
    <p>{`Hello everyone!`}</p>
    <div style={{
      padding: '20px 0 30px 0'
    }}>
      <p>{`I'm Aaron Uurman, a software developer living and working in Estonia.`}</p>
      <p>{`I got started in software development rather late,
after the second semester at university.
From that on I have never thought about doing something else,
I enjoy life as a developer.
I have been a software developer on Microsoft stack for more than 4 years and I like it.
In my free time, I enjoy playing disc-golf, running or hiking.`}</p>
      <div style={{
        margin: '30px 0'
      }}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "980px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75.10204081632654%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGxc1ri4jD/xAAaEAEBAAIDAAAAAAAAAAAAAAABAAIDERIT/9oACAEBAAEFAjYTuxL2IG6XGJf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8B1H//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BqKj/xAAYEAEAAwEAAAAAAAAAAAAAAAAAEDFBUf/aAAgBAQAGPwKeLa//xAAYEAEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAQABPyFW+RGnZhvdnDinbyJP/9oADAMBAAIAAwAAABAUH//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCQ0//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQIBAT8Qmlk//8QAHBABAQACAgMAAAAAAAAAAAAAAREAITFRQZGx/9oACAEBAAE/EERVC6cV0guusr0ua3Jp3cCMgkhZ88ZW8rSpT1n/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Tour du Mont Blanc",
              "title": "Tour du Mont Blanc",
              "src": "/static/731d42f530c7206c7a2ebb06fd140927/4012a/mont_blanc.jpg",
              "srcSet": ["/static/731d42f530c7206c7a2ebb06fd140927/a0fb2/mont_blanc.jpg 245w", "/static/731d42f530c7206c7a2ebb06fd140927/6b254/mont_blanc.jpg 490w", "/static/731d42f530c7206c7a2ebb06fd140927/4012a/mont_blanc.jpg 980w", "/static/731d42f530c7206c7a2ebb06fd140927/0302a/mont_blanc.jpg 1268w"],
              "sizes": "(max-width: 980px) 100vw, 980px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </div>
      <p>{`The picture above is taken in Tour du Mont Blanc.`}</p>
      <p>{`This blog purpose is to share knowledge and experience
I came across in my career by providing some tutorials and know-how via blog posts.
If you find that something can be done better or
this content is useful then please don't hesitate to let me know,
I would like to hear your opinion.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      